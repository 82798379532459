export enum Gender {
    Male = "male",
    Female = "female",
    Unknown = "unknown"
}


export enum AgencyStatus {
    Enabled = "Enabled",
    Disabled = "Disabled",
    Blocked = "Blocked"
}

export enum UserType {
    Driver,
    Rider,
    Admin
}

export enum SubscriptionStatus{
    Active = "active",
    Canceled = "canceled",
    Paused = "paused"
}

export enum Status{
    Open = "Open",
    Closed = "Closed",
    Resolved = "Resolved"
}

export enum subscriptionType{
    // FixedCostPerDriver = "Fixed Cost Per Driver",
    PerDriverFixedAmount = "PerDriverFixedAmount",
    FixedAmountWithPercentOfDriverEarning = "FixedAmountWithPercentOfDriverEarning",
    PercentOfDriverEarning = "PercentOfDriverEarning"
    // FixedCostForAgencyPlusPercentagePerDriver = "Fixed Cost of Agency + Percentage Per Driver",
    // PercentagePerDriver = "Percentage Per Driver"
}

export enum subscriptionValidityType{
    Monthly = "monthly",
    Yearly = "yearly"
}

export enum paymentType { 
    Paypal = "paypal",
    MMG = 'mmg',
    Cash = 'cash',
    Other = 'other'
}
